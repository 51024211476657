<template>
  <div class="box" v-title data-title="麦子众包实时大屏">
    <div class="box-1">
      <div class="title">
        <img src="../../public/img/logo.png" alt="">
        麦子众包实时大屏
      </div>
      <div class="content">
        <div class="content-1">
          <div class="content-title">
            用户统计
          </div>
          <div class="content-detail" v-if="user">
            <div class="detail-1">
              <div class="image">
                <img class="image1" src="../../public/img/icon1.png" alt="">
              </div>
              <p>{{ user[0] }}</p>
              <p>C端用户</p>
            </div>
            <div class="detail-1">
              <div class="image">
                <img class="image2" src="../../public/img/icon2.png" alt="">
              </div>
              <p>{{ user[2] }}</p>
              <p>B端用户</p>
            </div>
            <div class="detail-1">
              <div class="image">
                <img class="image2" src="../../public/img/icon11.png" alt="">
              </div>
              <p>{{ user[3] }}</p>
              <p>入驻服务商</p>
            </div>
          </div>
        </div>

        <div class="content-4">
          <div class="content-title">
            用户佣金和提现
          </div>
          <div class="content-detail" v-if="user && tixians">
            <div class="detail-title">
              用户佣金：
              <span>¥{{ user[1] }}</span>
            </div>
            <div class="detail-1">
              <div class="image">
                <img class="image1" src="../../public/img/icon3.png" alt="">
              </div>
              <p>提现未处理：{{ tixians[0] }}个</p>

            </div>
            <div class="detail-1">
              <div class="image">
                <img class="image2" src="../../public/img/icon4.png" alt="">
              </div>
              <p>提现已处理：{{ tixians[1] }}个</p>

            </div>
          </div>
        </div>


        <!-- <div class="content-2">
          <div class="content-title">
            商家和师傅入驻
          </div>
          <div class="content-detail">
            <div id="e-1" style="width: 5rem;height: 4rem;margin-top: -0.3rem;"></div>
          </div>
        </div> -->


        <!-- <div class="content-3">
          <div class="content-title">
            商品订单
          </div>
          <div class="content-detail">
            <div id="e-2" style="width: 2.6667rem;height: 2.6667rem;margin-top: 0.3333rem;"></div>
            <div class="details" v-if="goods">
              <div class="detail-1">
                <div class="r-1">
                  <div class="round"></div>
                </div>
                <div class="text">
                  总订单：{{ goods[0] }}
                </div>
              </div>
              <div class="detail-1">
                <div class="r-1">
                  <div class="round2"></div>
                </div>
                <div class="text">
                  未发货：{{ goods[1] }}
                </div>
              </div>
              <div class="detail-1">
                <div class="r-1">
                  <div class="round3"></div>
                </div>
                <div class="text">
                  已完成：{{ goods[3] }}
                </div>
              </div>
              <div class="detail-1">
                <div class="r-1">
                  <div class="round4"></div>
                </div>
                <div class="text">
                  未确认收货：{{ goods[2] }}
                </div>
              </div>
            </div>
          </div>
        </div> -->


        <div class="content-5">
          <div class="content-title">
            C端服务订单-金额统计
          </div>
          <div class="content-detail">
            <div id="e-3" style="width: 2.3333rem;height: 4rem;margin-top: -0.4667rem;"></div>
            <div class="details" v-if="services_money">
              <div class="detail-1">
                <div class="r-1">
                  <div class="round"></div>
                </div>
                <div class="text">
                  服务中：¥{{ services_money[0] }}
                </div>
              </div>
              <div class="detail-1">
                <div class="r-1">
                  <div class="round2"></div>
                </div>
                <div class="text">
                  已完成：¥{{ services_money[1] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content-6" v-if="b_services_money">
          <div class="content-title">
            B端服务订单-金额统计
          </div>
          <div class="detail-title">
            合同总金额：
            <span>¥{{ b_services_money[2] }}</span>
          </div>
          <div class="content-detail">

            <div id="e-4" style="width: 2.3333rem;height: 4rem;margin-top: -0.25rem;"></div>
            <div class="details">
              <div class="detail-1">
                <div class="r-1">
                  <div class="round"></div>
                </div>
                <div class="text">
                  服务中：¥{{ b_services_money[0] }}
                </div>
              </div>
              <div class="detail-1">
                <div class="r-1">
                  <div class="round2"></div>
                </div>
                <div class="text">
                  已完成：¥{{ b_services_money[1] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content-7">
          <div class="content-title">
            服务订单
          </div>
          <div class="content-detail" v-if="services">
            <div class="detail-1">
              <div class="image">
                <img class="image1" src="../../public/img/icon5.png" alt="">
              </div>
              <span>{{ services[5] }}</span>
              <p>已预约</p>
            </div>
            <div class="detail-1">
              <div class="image">
                <img class="image1" src="../../public/img/icon6.png" alt="">
              </div>
              <span>{{ services[0] }}</span>
              <p>待上门</p>
            </div>
            <div class="detail-1">
              <div class="image">
                <img class="image1" src="../../public/img/icon7.png" alt="">
              </div>
              <span>{{ services[1] }}</span>
              <p>待签约</p>
            </div>
            <div class="detail-1">
              <div class="image">
                <img class="image1" src="../../public/img/icon8.png" alt="">
              </div>
              <span>{{ services[2] }}</span>
              <p>待服务</p>
            </div>
            <div class="detail-1">
              <div class="image">
                <img class="image1" src="../../public/img/icon9.png" alt="">
              </div>
              <span>{{ services[3] }}</span>
              <p>服务中</p>
            </div>
            <div class="detail-1">
              <div class="image">
                <img class="image1" src="../../public/img/icon10.png" alt="">
              </div>
              <span>{{ services[4] }}</span>
              <p>已完成</p>
            </div>
          </div>
        </div>

        <div class="content-8">
          <div class="content-title">
            浏览数统计
          </div>

          <div class="content-detail" v-if="llNum">
            <div class="text-1">
              总浏览数：
              <span>{{ llNum[0] }}</span>
              次
            </div>
            <div class="text-1">
              今日浏览数：
              <span>{{ llNum[1] }}</span>
              次
            </div>
          </div>
        </div>
      </div>
      <div class="title" style="margin-top: -0.1667rem;font-size: 0.2667rem;
font-weight: 500;">一站式众包服务平台</div>
    </div>
  </div>
</template>

<script>
import { getZCTongJi } from '../api/all'
export default {
  data() {
    return {
      user: [],
      goods: [],
      tixians: [],
      b_services_money: [],
      services_money: [],
      services: [],
      timer: null,
      llNum: []
    }
  },
  async mounted() {
    await this.getZCTongJiApi()

    this.start()
  },
  methods: {
    //定时五分钟获取一次接口
    start() {
      this.timer = setInterval(() => {
        this.getZCTongJiApi()
      }, 1000 * 60 * 5);
    },
    getZCTongJiApi() {
      getZCTongJi()
        .then(res => {
          console.log(res)
          this.user = res.users
          this.goods = res.orders
          this.tixians = res.tixians
          this.b_services_money = res.b_services_money
          this.services_money = res.services_money
          console.log(this.services_money)
          this.services = res.services
          this.llNum  = res.liulans

         // this.getEchartData()
         // this.getEchartData2()
          this.getEchartData3()
          this.getEchartData4()
        })
    },
    getEchartData() {
      // 获取dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('e-1'));

      //绘制图表
      myChart.setOption({

        xAxis: {
          data: ['入驻商家', '入驻师傅'],
          axisTick: { show: false },

          // show:false
          // splitLine: { show: false },
          // type: 'category',
          // boundaryGap: false,

        },
        yAxis: {
          // splitLine: { show: false },
          // axisTick: { show: false },

        },
        series: [
          {
            name: '人数',
            type: 'bar',
            data: [
              {
                value: this.user[3],
                itemStyle: {
                  color: '#096BFF',
                  shadowColor: '#096BFF',
                }
              }
              ,
              {
                value: this.user[4],
                itemStyle: {
                  color: '#FF0909',
                  shadowColor: '#FF0909',

                },
              }],
            barWidth: '40%',
            label: {
              show: true
            }
          }
        ]
      });
    },

    getEchartData2() {
      // 获取dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('e-2'));
      // let option = {

      // };
      console.log(this.goods)
      myChart.setOption({
        series: [
          {
            type: 'pie',
            data: [
              {
                value: this.goods[1],
                name: '未发货',
                itemStyle: {
                  color: '#F3C96B'
                }
              },
              {
                value: this.goods[3],
                name: '已完成',
                itemStyle: {
                  color: '#9EC97F'
                }

              },
              {
                value: this.goods[2],
                name: '未确认收货',

                itemStyle: {
                  color: '#DE6E6A'
                }
              }
            ],
            labelLine: {
              normal: {
                show: false
              }
            },
            label: {
              normal: {
                position: 'inner'
              }
            },

          }
        ]
      })
    },


    getEchartData3() {
      // 获取dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('e-3'));
      //绘制图表
      myChart.setOption({

        xAxis: {
          // data: ['入驻商家', '入驻师傅'],
          axisTick: { show: false },

          show: false,
          splitLine: { show: false },
          // type: 'category',
          // boundaryGap: false,

        },
        yAxis: {
          data: ['已完成', '服务中'],
          show: false
          // splitLine: { show: false },
          // axisTick: { show: false },

        },
        series: [
          {
            name: '人数',
            type: 'bar',
            data: [
              {
                value: this.services_money[1],
                itemStyle: {
                  color: '#9EC97F',
                  shadowColor: '#9EC97F',
                }
              }
              ,
              {
                value: this.services_money[0],
                itemStyle: {
                  color: '#5971C0',
                  shadowColor: '#5971C0',

                },
              }],
            barWidth: '40%',
            label: {
              show: false
            }
          }
        ]
      });
    },


    getEchartData4() {
      // 获取dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('e-4'));
      let option = {
        xAxis: {
          // data: ['入驻商家', '入驻师傅'],
          axisTick: { show: false },

          show: false,
          splitLine: { show: false },
          // type: 'category',
          // boundaryGap: false,

        },
        yAxis: {
          data: ['已完成', '服务中'],
          show: false
          // splitLine: { show: false },
          // axisTick: { show: false },

        },
        series: [
          {
            name: '人数',
            type: 'bar',
            data: [
              {
                value: this.b_services_money[1],
                itemStyle: {
                  color: '#9EC97F',
                  shadowColor: '#9EC97F',
                }
              }
              ,
              {
                value: this.b_services_money[0],
                itemStyle: {
                  color: '#5971C0',
                  shadowColor: '#5971C0',

                },
              }],
            barWidth: '40%',
            label: {
              show: false
            }
          }
        ]
      }
      //绘制图表
      myChart.setOption(option)
      // myChart.setOption({

      //   xAxis: {
      //     // data: ['入驻商家', '入驻师傅'],
      //     axisTick: { show: false },

      //     show: false,
      //     splitLine: { show: false },
      //     // type: 'category',
      //     // boundaryGap: false,

      //   },
      //   yAxis: {
      //     data: ['已完成', '服务中'],
      //     show: false
      //     // splitLine: { show: false },
      //     // axisTick: { show: false },

      //   },
      //   series: [
      //     {
      //       name: '人数',
      //       type: 'bar',
      //       data: [
      //         {
      //           value: 2,
      //           itemStyle: {
      //             color: '#9EC97F',
      //             shadowColor: '#9EC97F',
      //           }
      //         }
      //         ,
      //         {
      //           value: 12,
      //           itemStyle: {
      //             color: '#5971C0',
      //             shadowColor: '#5971C0',

      //           },
      //         }],
      //       barWidth: '40%',
      //       label: {
      //         show: false
      //       }
      //     }
      //   ]
      // });
    },
  }
}
</script>

<style lang='less' scoped>
.box {
  height: 13.2667rem;
  width: 100%;
  background: linear-gradient(180deg, #FF9900 0%, #FFB36D 100%);


  &-1 {
    background-image: url('../../public/img/back1.png');
    background-size: 100% 99.5%;
    background-repeat: no-repeat;
    height: 100%;
    overflow: hidden;

    .title {
      font-size: 0.4667rem;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.9);
      text-align: center;
      margin-top: 0.1667rem;
      display: flex;
      align-items: center;
      justify-content: center;
      // line-height: 0.9375rem;
      img{
        width: 0.6rem;
        height: 0.6rem;
        margin-right: 0.1333rem;
      }
    }

    .content {
      width: 23.2rem;
      height: 12rem;
      background-image: url('../../public/img/back2.png');
      background-size: 100%;
      background-position: 0 -0.3333rem;
      background-repeat: no-repeat;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
      box-sizing: border-box;
      padding: 0 0.5rem;
      flex-wrap: wrap;

      &-1 {

        .content-title {
          background-image: url('../../public/img/titleBack.png');
          background-size: 100%;
          // background-position: 0 -0.1667rem;
          background-repeat: no-repeat;
          width: 4.7833rem;
          height: 0.6833rem;
          line-height: 0.6833rem;
          font-size: 0.2667rem;
          box-sizing: border-box;
          font-weight: bold;
          padding-left: 1.2rem;
          margin-top: 1.2rem;
          margin-bottom: 0.4833rem;
        }

        .content-detail {
          width: 5rem;
          height: 3.3333rem;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
          // box-shadow: 0px 4.002px 22.002px 0px #E1F1FF;

          box-shadow: 0rem 0.0667rem 0.3667rem 0rem #FFECE1;
          border-radius: 0rem 0rem 0rem 0rem;
          display: flex;
          justify-content: space-evenly;

          .detail-1 {
            height: 1.6667rem;
            font-size: 0.3rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4E4E4E;
            text-align: center;
            height: 2rem;
            margin-top: 0.7833rem;

            .image {
              height: 1rem;
              align-items: flex-end;
            }

            .image1 {
              width: 0.8rem;
              height: 0.8rem;
            }

            .image2 {
              width: 0.9333rem;
              height: 0.9333rem;
            }

            p {
              margin-bottom: 0.0667rem;
            }
          }
        }
      }

      &-2 {

        .content-title {
          background-image: url('../../public/img/titleBack.png');
          background-size: 100%;
          // background-position: 0 -0.1667rem;
          background-repeat: no-repeat;
          width: 4.7833rem;
          height: 0.6833rem;
          line-height: 0.6833rem;
          font-size: 0.2667rem;
          box-sizing: border-box;
          font-weight: bold;
          padding-left: 1.2rem;
          margin-top: 1.2rem;
          margin-bottom: 0.4833rem;
        }

        .content-detail {
          width: 5rem;
          height: 3.3333rem;
          background: #FFFFFF;
          box-shadow: 0rem 0.0667rem 0.3667rem 0rem #FFECE1;
          border-radius: 0rem 0rem 0rem 0rem;
          display: flex;
          overflow: hidden;

          .detail-1 {

            font-size: 0.3rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4E4E4E;




          }


        }
      }

      &-3 {

        .content-title {
          background-image: url('../../public/img/titleBack.png');
          background-size: 100%;
          // background-position: 0 -0.1667rem;
          background-repeat: no-repeat;
          width: 4.7833rem;
          height: 0.6833rem;
          line-height: 0.6833rem;
          font-size: 0.2667rem;
          box-sizing: border-box;
          font-weight: bold;
          padding-left: 1.2rem;
          margin-top: 1.2rem;
          margin-bottom: 0.4833rem;
        }

        .content-detail {
          width: 5rem;
          height: 3.3333rem;
          background: #FFFFFF;
          box-shadow: 0rem 0.0667rem 0.3667rem 0rem #FFECE1;
          border-radius: 0rem 0rem 0rem 0rem;
          display: flex;

          .details {
            display: flex;
            flex-direction: column;
            // align-items: center;
            justify-content: center;
          }

          .detail-1 {

            font-size: 0.3rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4E4E4E;
            font-size: 0.2rem;
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;

            // .text{
            //   width: 70% ;
            // }
            .r-1 {
              width: 0.2667rem;
              margin-right: 0.2333rem;
            }

            .round {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #5971C0;

            }

            .round2 {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #F3C96B;
              // margin-right: 0.2667rem;
            }

            .round3 {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #9EC97F;
              // margin-right: 0.2667rem;
            }

            .round4 {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #DE6E6A;
              // margin-right: 0.2667rem;
            }
          }
        }
      }

      &-4 {

        .content-title {
          background-image: url('../../public/img/titleBack.png');
          background-size: 100%;
          // background-position: 0 -0.1667rem;
          background-repeat: no-repeat;
          width: 4.7833rem;
          height: 0.6833rem;
          line-height: 0.6833rem;
          font-size: 0.2667rem;
          box-sizing: border-box;
          font-weight: bold;
          padding-left: 1.2rem;
          margin-top: 1.2rem;
          margin-bottom: 0.4833rem;
        }

        .content-detail {
          width: 5rem;
          height: 3.3333rem;
          background: #FFFFFF;
          box-shadow: 0rem 0.0667rem 0.3667rem 0rem #FFECE1;
          border-radius: 0rem 0rem 0rem 0rem;
          display: flex;
          flex-direction: column;
          align-items: center;

          .detail-title {
            font-size: 0.2333rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            margin-top: 0.28723rem;
            margin-bottom: 0.2833rem;
            font-weight: bold;

            span {
              color: #FF0000;
            }
          }

          .detail-1 {
            height: 1.6667rem;
            font-size: 0.2333rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            display: flex;
            align-items: center;
            height: 1.1667rem;

            .image {
              margin-right: 0.1667rem;
            }

            img {
              width: 0.8rem;
              height: 0.8rem;
            }
          }
        }
      }


      &-5 {
        margin-top: 1.0667rem;

        .content-title {
          background-image: url('../../public/img/titleBack.png');
          background-size: 100%;
          // background-position: 0 -0.1667rem;
          background-repeat: no-repeat;
          width: 4.3333rem;
          height: 0.6833rem;
          line-height: 0.6833rem;
          font-size: 0.2667rem;
          box-sizing: border-box;
          font-weight: bold;
          padding-left: 1.2rem;
          margin-top: 0.1667rem;
          margin-bottom: 0.4833rem;
        }

        .content-detail {
          width: 4.4667rem;
          height: 2.6333rem;
          background: #FFFFFF;
          box-shadow: 0rem 0.0667rem 0.3667rem 0rem #FFECE1;
          border-radius: 0rem 0rem 0rem 0rem;
          display: flex;
          overflow: hidden;

          .details {
            display: flex;
            flex-direction: column;
            // align-items: center;
            justify-content: center;
          }

          .detail-1 {

            font-size: 0.3rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4E4E4E;
            font-size: 0.2rem;
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;

            // .text{
            //   width: 70% ;
            // }
            .r-1 {
              width: 0.2667rem;
              margin-right: 0.2333rem;
            }

            .round {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #5971C0;

            }

            .round2 {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #F3C96B;
              // margin-right: 0.2667rem;
            }

            .round3 {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #9EC97F;
              // margin-right: 0.2667rem;
            }

            .round4 {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #DE6E6A;
              // margin-right: 0.2667rem;
            }
          }

        }
      }

      &-6 {
        position: relative;
        margin-top: 1.0667rem;

        .content-title {
          background-image: url('../../public/img/titleBack.png');
          background-size: 100%;
          // background-position: 0 -0.1667rem;
          background-repeat: no-repeat;
          width: 4.3333rem;
          height: 0.6833rem;
          line-height: 0.6833rem;
          font-size: 0.2667rem;
          box-sizing: border-box;
          font-weight: bold;
          padding-left: 1.2rem;
          margin-top: 0.1667rem;
          margin-bottom: 0.4833rem;
        }

        .detail-title {
          font-size: 0.2333rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          margin-left: 0.2667rem;
          font-weight: bold;
          // margin-top: 0.3333rem;
          // margin-bottom: -0.3rem;
          top: 26%;
          position: absolute;

          span {
            color: #FF0000;
          }
        }

        .content-detail {
          width: 4.4667rem;
          height: 2.6333rem;
          background: #FFFFFF;
          box-shadow: 0rem 0.0667rem 0.3667rem 0rem #FFECE1;
          border-radius: 0rem 0rem 0rem 0rem;
          display: flex;
          overflow: hidden;

          .details {
            display: flex;
            flex-direction: column;
            // align-items: center;
            justify-content: center;
          }


          .detail-1 {

            font-size: 0.3rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4E4E4E;
            font-size: 0.2rem;
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;

            // .text{
            //   width: 70% ;
            // }
            .r-1 {
              width: 0.2667rem;
              margin-right: 0.2333rem;
            }

            .round {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #5971C0;

            }

            .round2 {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #F3C96B;
              // margin-right: 0.2667rem;
            }

            .round3 {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #9EC97F;
              // margin-right: 0.2667rem;
            }

            .round4 {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 0.1rem;
              background-color: #DE6E6A;
              // margin-right: 0.2667rem;
            }
          }


        }
      }

      &-7 {
        margin-top: -1.1667rem;

        .content-title {
          background-image: url('../../public/img/titleBack.png');
          background-size: 100%;
          // background-position: 0 -0.1667rem;
          background-repeat: no-repeat;
          width: 4.3333rem;
          height: 0.6833rem;
          line-height: 0.6833rem;
          font-size: 0.2667rem;
          box-sizing: border-box;
          font-weight: bold;
          padding-left: 1.2rem;
          margin-top: 0.1667rem;
          margin-bottom: 0.4833rem;
        }

        .content-detail {
          width: 11.4667rem;
          height: 2.6333rem;
          background: #FFFFFF;
          box-shadow: 0rem 0.0667rem 0.3667rem 0rem #FFECE1;
          border-radius: 0rem 0rem 0rem 0rem;
          display: flex;
          justify-content: space-evenly;

          .detail-1 {
            // height: 1.6667rem;
            font-size: 0.3rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4E4E4E;
            text-align: center;
            // height: 2rem;

            .image {
              height: 1rem;
              align-items: flex-end;
              margin-top: 0.2667rem;
              margin-bottom: 0.1833rem;
            }

            .image1 {
              width: 1.0667rem;
              height: 1.0667rem;
            }

            span {
              display: block;
              margin-bottom: 0.1333rem;

            }

            p {
              margin-bottom: 0.0667rem;
            }
          }
        }
      }

      .content-8 {
        margin-top: -1.1667rem;


        .content-title {
          background-image: url('../../public/img/titleBack.png');
          background-size: 100%;
          // background-position: 0 -0.1667rem;
          background-repeat: no-repeat;
          width: 4.3333rem;
          height: 0.6833rem;
          line-height: 0.6833rem;
          font-size: 0.2667rem;
          box-sizing: border-box;
          font-weight: bold;
          padding-left: 1.2rem;
          margin-top: 0.1667rem;
          margin-bottom: 0.4833rem;
        }

        .content-detail {
          width: 4.4667rem;
          height: 2.6333rem;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
          box-shadow: 0rem 0.0667rem 0.3667rem 0rem #FFECE1;
          border-radius: 0.1333rem;
          font-size: 0.2333rem;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          color: #000000;
          padding-left: 0.5rem;
          justify-content: center;

          .text-1 {
            margin-bottom: 0.2667rem;

            span {
              color: #FF7A00;
            }
          }
        }
      }

    }
  }
}
</style>
