<template>
  <div id="app">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <DataVisualization/>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import DataVisualization from './views/dataVisualization.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld,
    DataVisualization
  }
}
</script>

<style>

</style>
