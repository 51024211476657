import { render, staticRenderFns } from "./dataVisualization.vue?vue&type=template&id=07bc70cd&scoped=true"
import script from "./dataVisualization.vue?vue&type=script&lang=js"
export * from "./dataVisualization.vue?vue&type=script&lang=js"
import style0 from "./dataVisualization.vue?vue&type=style&index=0&id=07bc70cd&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07bc70cd",
  null
  
)

export default component.exports