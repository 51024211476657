import Vue from 'vue'
import App from './App.vue'
import './utils/flexible'
import * as echarts from 'echarts';

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.directive('title', {
  inserted: function (el) {
  document.title = el.dataset.title
  }
  })

new Vue({
  render: h => h(App),
}).$mount('#app')
